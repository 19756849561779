import { FC } from 'react'

import { Drawer, DrawerProps } from 'antd'

import './index.scss'

const FullScreenDrawer: FC<DrawerProps> = ({ ...baseProps }) => {
  return <Drawer placement={'top'} height={'100%'} width={'100%'} {...baseProps} />
}

export { FullScreenDrawer }
