import { DateTz } from '../../api'
import { dayjs } from '../../services/dayjs'
import { ApiEndpoint, createApiResource } from '../api/endpoint'
import { ApiEntity } from '../api/entity'
import { Passbook } from '../passbook'
import { ScanData } from '../scanData'
import { CustomerEntity } from './account'


export type TicketTransferRequest = {
  firstName: string
  lastName: string
  email?: string
  mobileNumber?: string
  message?: string
}

class AccountTicket extends ApiEntity implements Data.Transferable, Data.Scannable, Data.TemporalLocal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
    guest: CustomerEntity,
    owner: CustomerEntity,
  }
  static urlRoot = `/api/account/events/tickets`
  readonly id: number = 0
  readonly ticketId: number = 0
  readonly eventId: number = 0
  readonly customerId: number = 0
  readonly orderItemId: number = 0
  readonly ownerId: number = 0
  readonly serial: string = ''
  readonly status: API.AccountTicketStatus = 'active'
  readonly price: number = 0
  readonly purchaseDate: string = ''
  readonly scanDate: string = ''
  readonly portalUrl: string = ''
  readonly scanData: ScanData = ScanData.fromJS()
  readonly title: string = ''
  readonly eventTitle: string = ''
  readonly description: string = ''
  readonly attendee?: CustomerEntity
  readonly venue: string = ''
  readonly venueAddress: string = ''
  readonly owner?: CustomerEntity
  readonly guest?: CustomerEntity
  readonly passbook: Passbook = Passbook.fromJS()
  readonly photo: string = ''
  readonly cancelable: boolean = false
  readonly transferable: boolean = false
  readonly transferred: boolean = false
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
}

const AccountTicketBase = createApiResource({
  path: '/api/account/events/tickets/:id',
  schema: AccountTicket,
})

const transfer = new ApiEndpoint({
  path: '/api/account/events/tickets/:id/transfer',
  method: 'PATCH',
  body: {} as TicketTransferRequest,
  schema: AccountTicket,
})

const getTransferred = new ApiEndpoint({
  path: '/api/passes/guest-ticket/:serial',
  method: 'GET',
  schema: AccountTicket,
})

const getTickets = new ApiEndpoint({
  path: '/api/account/events/:id/tickets',
  method: 'GET',
  schema: [AccountTicket],
})

const AccountTicketResource = {
  ...AccountTicketBase,
  transfer,
  getTransferred,
  tickets: getTickets
}
export { AccountTicket, AccountTicketResource }
