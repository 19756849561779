import { FC, memo } from 'react'

import { Image as BaseImage } from 'antd'

import { useConfig } from 'src/providers'

const assets: Record<`./assets/placeholders/${Design.PlaceholderIcon}.svg`, string> = import.meta.glob(
  './assets/placeholders/*.svg',
  {
    import: 'default',
    eager: true,
  },
)

// const optimizedSrc = (src: string, number: 1 | 2 | 3, ratio: number) =>
//   `${src.substring(0, src.length - 4)}@${number}${src.substring(src.length - 4)} ${ratio}w`

const ResolveCdnSource = (src: string) => {
  const { cdn } = useConfig()

  if (!src.startsWith('http') || src.startsWith(cdn)) {
    src = `${src.replace(cdn, `${location.protocol}//${location.host}/cdn`)}`
  }
  return src
}

const Image: FC<SDK.Components.ImageProps> = memo(
  ({
    src: defaultSrc = '',
    alt = '',
    placeholder = 'empty',
    preview = false,
    loading = false,
    className,
    style,
    height = '100%',
    width = '100%',
    fallback = assets[`./assets/placeholders/${placeholder}.svg`],
    ...props
  }) => {
    let src = ResolveCdnSource(!loading ? defaultSrc : fallback)

    return (
      <BaseImage
        wrapperStyle={{
          width: '100%',
          height: height,
          justifyContent: 'center',
          margin: '0 auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
        style={{
          objectFit: defaultSrc !== '' ? 'cover' : 'contain',
          objectPosition: 'center',
          // background: fallback && `#f8f8f8 url(${fallback}) no-repeat center center`,
          textIndent: '-9999px',
          width: '100%',
          ...style,
        }}
        height={'100%'}
        width={'100%'}
        src={!loading ? ResolveCdnSource(src) : ''}
        alt={''}
        preview={preview}
        placeholder={null}
        fallback={fallback}
        {...props}
      />
    )
  },
)

export { Image }
