import { RcFile } from 'antd/lib/upload'

import type { Promise } from 'cypress/types/cy-bluebird'

import { toBase64 } from '../helpers/file'
import { dayjs } from '../services/dayjs'
import { ApiEndpoint, createApiResource } from './api/endpoint'
import ApiEntity from './api/entity'

export type DataMapType =
  | ''
  | 'attribute'
  | 'first_name'
  | 'middle_name'
  | 'last_name'
  | 'gender'
  | 'company_name'
  | 'company_title'
  | 'email'
  | 'cc_email'
  | 'mobile_number'
  | 'phone_number'
  | 'address'
  | 'address2'
  | 'city'
  | 'state'
  | 'zip_code'
  | 'country'
  | 'birthdate'
  | 'anniversary_date'
  | 'website'
  | 'device_type'
  | 'opt_in_email'
  | 'opt_in_sms'
  | 'facebook_handle'
  | 'twitter_handle'
  | 'instagram_handle'
  | 'linkedin_handle'
  | 'profile_photo'
  | 'customer_reference'
  | 'customer_type'
  | 'timezone'
  | 'username'
  | 'password'

export type FieldValue = {
  text: string
  value: string
  selected: boolean
}

export type FieldType =
  | 'canvas'
  | 'radio'
  | 'checkbox'
  | 'select'
  | 'textarea'
  | 'input'
  | 'password'
  | 'datetime'
  | 'section'
  | 'hidden'
  | 'upload'
  | 'html'

export type Field = {
  format: string
  help: string
  label: string
  name: string
  style: string
  type: FieldType
  required: boolean
  placeholder?: string
  value?: string
  values?: FieldValue[]
}

export class SurveyAnswer extends ApiEntity {
  static schema = {
    createdOn: (iso: string) => dayjs(iso).toDate(),
    modifiedOn: (iso: string) => dayjs(iso).toDate(),
  }
  fieldId: Data.ID
  surveyId: Data.ID
  responseId: Data.ID
  answer: string
  modifiedOn: Date
  createdOn: Date
}

class SurveyResponse extends ApiEntity {
  static schema = {
    answers: [SurveyAnswer],
    createdOn: (iso: string) => dayjs(iso).toDate(),
  }
  surveyId: Data.ID = 0
  createdOn: Date
  answers: SurveyAnswer[] = []
}

class SurveyFieldEntity extends ApiEntity {
  order: number = 0
  dataMap: DataMapType = ''
  format: string
  help: string
  label: string
  name: string
  style: string
  type: FieldType
  required: boolean
  placeholder?: string
  value?: string
  values?: FieldValue[]
}

class SurveyEntity extends ApiEntity implements Data.Identified {
  static schema = {
    fields: [SurveyFieldEntity],
    responses: [SurveyResponse],
  }
  id: Data.ID = ''
  uid: string = ''
  name: string = ''
  description: string = ''
  businessId: number = 0
  buttonCreate?: string
  buttonEdit: string = ''
  hasSubmitted: boolean = false
  canSubmit: boolean = true
  confirmationHeader?: string
  confirmationText?: string
  multipleResponses: boolean = false
  referenceId: number = 0
  referenceType: string = ''
  createdOn: string = ''
  endpoint: string = ''
  fields: SurveyFieldEntity[] = []
  responses: SurveyResponse[] = []

  pk() {
    return this.uid ?? this.id
  }

  get hasRequiredFields() {
    return this.fields.some((i) => i.required)
  }

  static async parseValues(values: FormFieldValue[]): Promise<FormFieldValue[]> {
    // Creates an array of promises
    // Handles converting files to base64 and replacing the answer with the base64 value
    // Standard form answers get returned with their original value
    const valuePromises: Promise<FormFieldValue>[] = values.map(async (value) => {
      if (typeof value.answer === 'object' && 'file' in value.answer && value.answer.file) {
        return await toBase64(value.answer.file).then((base64) => ({
          fieldId: value.fieldId,
          answer: base64 ?? '',
        }))
      } else {
        return new Promise((resolve) => resolve(value))
      }
    })

    return await Promise.all(valuePromises)
  }
}

export class FormFieldFileValue {
  file?: RcFile
  fileList?: RcFile[]
}

export type FormFieldValue = {
  fieldId: number
  answer: string | string[] | number | FormFieldFileValue
}

type ExperienceType =
  | 'privacy-policy'
  | 'contact'
  | 'register'
  | 'club-visit'
  | 'preferences'
  | 'points-missing'
  | 'reviews'
  | 'restaurant'
  | 'review_business'
  | 'guest-pass'
  | 'nominate-member'
  | 'other'

class SurveyEndpoint extends ApiEntity {
  static urlRoot = ''
  readonly uid: string = ''
  readonly endpoint: string = ''
  readonly referenceType: string = ''
  readonly referenceId: number = 0
}

const SurveyResourceBase = createApiResource({
  path: '/api/surveys/:id',
  schema: SurveyEntity,
}).extend('submit', {
  method: 'POST',
  body: {} as FormFieldValue[],
  schema: SurveyEntity,
})

const PublicSurveyResource = createApiResource({
  path: '/api/public/surveys/:id',
  schema: SurveyEntity
}).extend('submit', {
  path: '/api/public/surveys/:uid',
  method: 'POST',
  body: SurveyResourceBase.submit.body,
})

// static async submit(id: Data.ID, fieldValues: FormFieldValue[], url?: string): Promise<any> {
//   const values = await this.parseValues(fieldValues)
//   return await super.fetch(url ? url : `${this.urlRoot}/${id}`, {
//     method: 'POST',
//     body: JSON.stringify(values),
//   })
// }

// const submit = new ApiEndpoint({
//   path: '/api/surveys/:id',
//   method: 'POST',
//   body: {} as FormFieldValue[],
//   schema: SurveyEntity,
// })

// const getPublic = new ApiEndpoint({
//   path: '/api/public/surveys/:id',
//   method: 'GET',
//   schema: SurveyEntity,
// })

// const accountSurveys = {
//   submitPublic: submit.extend({
//     path: '/api/public/surveys/:uid',
//   }),
// }

const experienceEndpoints = {
  get: new ApiEndpoint({
    path: '/api/surveys/experiences/:type',
    method: 'GET',
    searchParams: {} as {
      type: ExperienceType
    },
    schema: SurveyEntity,
  }),
  getPublic: new ApiEndpoint({
    path: '/api/public/surveys/experiences/:type',
    method: 'GET',
    schema: SurveyEntity,
  }),
  submit: SurveyResourceBase.submit.extend({
    path: '/api/surveys/experiences/:type',
  }),
}

const SurveyResource = {
  ...SurveyResourceBase,
  getList: SurveyResourceBase.getList.extend({ searchParams: {} as { uids: string } }),
  experience: experienceEndpoints,
  account: PublicSurveyResource
}

export { SurveyEndpoint, SurveyEntity, SurveyFieldEntity, SurveyResource }

