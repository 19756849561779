import { CSSProperties, FC, PropsWithChildren, ReactNode, memo, useCallback, useMemo } from 'react'

import { Checkbox, Flex } from 'antd'

import { useColor } from '../app'
import { ListItemMeta } from '../list/ListItem'
import { Text, Title } from '../text'
import styles from './styles/cardcheckable.module.css'

const MemorizedTitle = memo(Title)
const MemorizedText = memo(Text)
const MemorizedListItem = memo(ListItemMeta)

export type CheckableCardProps = {
  checked?: boolean
  disabled?: boolean
  enableUncheck?: boolean
  onChange?: (checked: boolean) => void
  avatar?: SDK.Components.AvatarProps
  extra?: ReactNode | 'checkbox'
  title?: ReactNode
  description?: ReactNode
  bordered?: boolean
  style?: CSSProperties
  className?: string
  checkbox?: boolean
  checkTheme?: 'bordered' | 'background'
}

export const CheckableCard: FC<PropsWithChildren<CheckableCardProps>> = ({
  checked: checked = false,
  enableUncheck,
  onChange = () => {},
  disabled = false,
  extra = false,
  bordered = true,
  style,
  title,
  description,
  avatar,
  checkTheme = 'background',
}) => {
  const handleChange = useCallback(() => {
    if (disabled || (checked && !enableUncheck)) return
    onChange(!checked)
  }, [disabled, onChange])

  const { token, components } = useColor()

  const backgroundColor = useMemo(
    () => (checked && checkTheme === 'background' ? components.Radio.buttonCheckedBg : 'transparent'),
    [checked, checkTheme],
  )
  const borderColor = useMemo(
    () =>
      `1px solid ${bordered || checkTheme === 'bordered' ? (checked ? token.colorPrimary : token.colorBorder) : 'transparent'}`,
    [checked, checkTheme],
  )

  const descriptionSection = useMemo(
    () => (
      <Flex gap={0} align={'start'} vertical>
        {typeof title === 'string' ? (
          <MemorizedTitle level={5} weight={600}>
            {title}
          </MemorizedTitle>
        ) : (
          title
        )}
        {typeof description === 'string' ? <MemorizedText>{description}</MemorizedText> : description}
      </Flex>
    ),
    [title, description],
  )

  return (
    <MemorizedListItem
      align={'middle'}
      onClick={handleChange}
      avatar={avatar}
      title={descriptionSection}
      extra={
        extra &&
        (extra === 'checkbox' ? (
          <Checkbox disabled={disabled} checked={checked} style={{ pointerEvents: 'none' }} />
        ) : (
          extra
        ))
      }
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: backgroundColor,
        border: borderColor,
        borderRadius: token.borderRadius,
        opacity: disabled ? 0.35 : 1,
        ...style,
      }}
      className={styles.pvCardCheckable}
    />
  )
}
