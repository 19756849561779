import { CSSProperties, FC } from 'react'

import { useColor } from '../app'
import { Button } from '../button/Button'

const DrawerCloseButton: FC<{
  drawerPlacement: 'right' | 'fullscreen' | 'left'
  onClick?: VoidFunction
  loading?: boolean
  customStyle?: CSSProperties
}> = ({ onClick, drawerPlacement, loading, customStyle = {} }) => {
  const { components } = useColor()
  const style: CSSProperties =
    drawerPlacement === 'right'
      ? {
          position: 'absolute',
          top: 84,
          left: -30,
          zIndex: 1001,
          ...customStyle,
        }
      : drawerPlacement === 'left'
        ? {
            position: 'absolute',
            top: 84,
            right: -24,
            zIndex: 1001,
            ...customStyle,
          }
        : {
            position: 'absolute',
            top: 16,
            right: 24,
            height: 48,
            width: 48,
            textAlign: 'center',
            fontSize: 16,
            padding: '8px 14px',
            zIndex: 1111,
            ...customStyle,
          }

  return (
    <Button
      data-cy={'DrawerCloseButton'}
      loading={loading}
      icon={'symbol/close'}
      theme={'light'}
      shape={'circle'}
      type={'text'}
      block
      onClick={onClick}
      style={{ ...style }}
    />
  )
}

export { DrawerCloseButton }
