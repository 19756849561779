import { DateTz } from '../../api'
import { dayjs } from '../../services/dayjs'
import { ApiEntity } from '../api/entity'
import { BusinessLocation } from '../business'
import { CompanyConfig } from '../company'
import { CustomerEntity } from './account'

export interface AccountScheduleFilter extends API.PaginationParams {
  type?: 'all' | 'appointment' | 'reservation' | 'event'
  upcoming?: string
  date?: string
  status?: string
}

class AccountScheduleItem extends ApiEntity implements Data.TemporalLocal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
    location: BusinessLocation
  }
  readonly title: string = ''
  readonly description: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly quantity: number = 1
  readonly company?: CompanyConfig
  readonly type: API.BookingItemType = 'appointment'
  readonly blockType: string = ''
  readonly guest?: CustomerEntity
  readonly customer?: CustomerEntity
  readonly location?: BusinessLocation
  readonly cancelable: boolean = false
}

export { AccountScheduleItem }
