import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  lazy,
  useCallback,
  useContext,
  useMemo,
  useState,
  useTransition,
} from 'react'

import { CenteredContent } from '@peoplevine/sdk/components'
import { FullScreenDrawer } from '@peoplevine/sdk/components/drawer'
import { DrawerCloseButton } from '@peoplevine/sdk/components/drawer/DrawerCloseButton'

type DrawerTypes = 'order'

type DrawerFuncs = {
  [key in DrawerTypes as `${key}Drawer`]: () => {
    show: (id: Data.ID) => void
    loading: boolean
  } & {
    [K in `${Capitalize<key>}Drawer`]: ReactNode
  }
}

const DrawerComponents: { [key in DrawerTypes]: FC<Data.Identified> } = {
  order: lazy(() => import('../components/account/tabs/order/Order')),
}

type ContextProps = {} & DrawerFuncs

const defaultValues: ContextProps = {
  orderDrawer: () => ({ show: () => {}, loading: false, OrderDrawer: null }),
}

const DrawerDetails = createContext<ContextProps>(defaultValues)

const DrawerLayout: FC<PropsWithChildren<{ id: Data.ID; open: boolean; onClose: (id: Data.ID) => void }>> = ({
  id,
  open = false,
  onClose,
  children,
}) => {
  return (
    <FullScreenDrawer
      closeIcon={<DrawerCloseButton drawerPlacement={'fullscreen'} />}
      open={open}
      mask={false}
      forceRender={true}
      destroyOnClose={false}
      maskClosable={false}
      onClose={() => onClose(id)}
      styles={{
        header: {
          borderBottom: 'none',
          height: 0,
          paddingBottom: 0,
        },
        body: { padding: 16 },
      }}
    >
      <CenteredContent width={1200}>{children}</CenteredContent>
    </FullScreenDrawer>
  )
}

const DrawerDetailProvider: FC<PropsWithChildren> = ({ children }) => {
  const orderDrawer = () => {
    const [id, setID] = useState<Data.ID | null>(null)
    const [isPending, startTransition] = useTransition()
    const Component = DrawerComponents['order']

    const handleOpen = useCallback(
      (id: Data.ID) => {
        startTransition(() => setID(id))
      },
      [id],
    )

    const Drawer = useMemo(
      () =>
        id && (
          <DrawerLayout id={id} open={!!id} onClose={() => setID(null)}>
            <Component id={id} />
          </DrawerLayout>
        ),
      [id],
    )

    return {
      show: handleOpen,
      loading: isPending,
      OrderDrawer: Drawer,
    }
  }

  return <DrawerDetails.Provider value={{ orderDrawer }}>{children}</DrawerDetails.Provider>
}

const useDrawerDetails: () => ContextProps = () => useContext(DrawerDetails)

export { DrawerDetailProvider, useDrawerDetails }
