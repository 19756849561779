import { FC, useState } from 'react'

import { useLoading } from '@data-client/hooks'

import Form, { Button, Item } from '@peoplevine/sdk/components/form'
import { FormItem } from '@peoplevine/sdk/components/form/FormItem'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'

import { useActivate } from './ActivateProvider'

interface IActivateAccount {
  code: string
  customerId: number
}

const ActivateAccount: FC<IActivateAccount> = ({ code, customerId }) => {
  const { activate } = useActivate()
  const [enabled, setEnabled] = useState(false)

  const [handleActivate, activating] = useLoading(
    (password: string) =>
      activate({
        password,
        code: code,
        customerId: customerId,
      }),
    [code, customerId],
  )

  return (
    <VerticalSpace size={'large'}>
      <VerticalSpace>
        <Title level={1}>Set Your Password</Title>
        <Text type={'secondary'}>Let's secure your account with a new password.</Text>
      </VerticalSpace>
      <Form<{ password: string }> onFinish={(values) => handleActivate(values.password)} disabled={activating}>
        <FormItem.Password onValidate={setEnabled} name={'password'} showLevel />
        <Item>
          <Button icon={'directional/arrow-right'} loading={activating} disabled={!enabled} block htmlType={'submit'}>
            Continue
          </Button>
        </Item>
      </Form>
    </VerticalSpace>
  )
}

export default ActivateAccount
