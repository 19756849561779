import { AccountEntity, AccountRegistered, TokenEntity, type AccountRegister } from '../../datasource/account/account'
import { AccountAppointment, CancelAppointmentResponse } from '../../datasource/account/booking/appointment'
import { AccountEventSummary, CancelEventResponse } from '../../datasource/account/booking/event'
import { AccountReservation, CancelReservationResponse } from '../../datasource/account/booking/reservation'
import { AccountScheduleItem } from '../../datasource/account/schedule'
import { ApiEndpoint, createApiResource } from '../../datasource/api/endpoint'
import { FormFieldValue, SurveyEntity } from '../../datasource/survey'
import { PasswordCodeVerifyResponse, PasswordReset, PasswordResetResponse } from '../../datasource/token'

const AccountBaseResource = createApiResource({
  path: '/api/account/:id?',
  schema: AccountEntity,
})
const signout = new ApiEndpoint({
  path: '/api/token/revoke',
  method: 'POST',
  body: {} as {
    refreshToken: string
  }
})
const token = new ApiEndpoint({
  path: '/api/token',
  method: 'POST',
  body: {} as API.LoginRequest,
  schema: TokenEntity,
})

const sso = new ApiEndpoint({
  path: '/api/token',
  method: 'POST',
  body: {} as API.LoginRequest,
  schema: TokenEntity,
})

const registered = new ApiEndpoint({
  path: '/api/public/account/registered/:username',
  method: 'GET',
  schema: AccountRegistered,
})
const register = new ApiEndpoint({
  path: '/api/public/account/register',
  method: 'POST',
  body: {} as AccountRegister,
  schema: {} as {
    activated: boolean
    firstName: string
  },
})
const activate = new ApiEndpoint({
  path: '/api/public/account/activate',
  method: 'POST',
  body: {} as {
    code: string
    password: string
    customerId: number
  },
  schema: AccountRegistered,
})
const partialUpdate = new ApiEndpoint({
  path: '/api/account',
  method: 'PATCH',
  body: {} as Partial<AccountEntity>,
  schema: AccountEntity,
  process: (value) => value as AccountEntity,
})
const updateProfilePhoto = new ApiEndpoint({
  path: '/api/file/account/profile-photo',
  method: 'POST',
  body: {} as File,
  schema: AccountEntity,
})
const getPersonalDetails = new ApiEndpoint({
  path: '/api/account/personal-details',
  method: 'GET',
  schema: SurveyEntity,
})
const updatePersonalDetails = new ApiEndpoint({
  path: '/api/account/personal-details',
  method: 'PATCH',
  body: {} as FormFieldValue[],
  schema: SurveyEntity,
})
const forgotPassword = new ApiEndpoint({
  path: '/api/public/verify/password-reset',
  method: 'POST',
  body: {} as PasswordReset,
  schema: {
    success: Boolean,
    message: String,
  },
})

const verifyPasswordCode = new ApiEndpoint({
  path: '/api/public/verify/password-reset/:code',
  method: 'GET',
  schema: {} as PasswordCodeVerifyResponse,
  process: (value) => value as PasswordCodeVerifyResponse,
})
const passwordReset = new ApiEndpoint({
  path: '/api/public/verify/password-reset/:code',
  method: 'POST',
  body: {} as PasswordReset,
  process: (value) => value as PasswordResetResponse,
})

interface AccountScheduleFilter {
  type?: 'all' | 'appointment' | 'reservation' | 'event'
  upcoming?: string
  startDate?: string
  endDate?: string
  date?: string
}

const schedule = new ApiEndpoint({
  path: '/api/account/my-schedule',
  method: 'GET',
  schema: [AccountScheduleItem],
  searchParams: {} as AccountScheduleFilter,
})

const me = new ApiEndpoint({
  path: '/api/account',
  method: 'GET',
  schema: AccountEntity,
})

const AccountEvents = createApiResource({
  path: '/api/account/events/:id',
  schema: AccountEventSummary,
  searchParams: {} as Partial<API.AccountBookingRequest>,
}).extend('cancel', {
  path: '/api/account/events/:id/cancel',
  method: 'POST',
  schema: CancelEventResponse,
})


const AccountReservations = createApiResource({
  path: '/api/account/reservations/:id',
  schema: AccountReservation,
  searchParams: {} as Partial<API.AccountBookingRequest>,
}).extend('cancel', {
  path: '/api/account/reservations/:id/cancel',
  method: 'POST',
  body: {},
  schema: CancelReservationResponse,
})

const AccountAppointments = createApiResource({
  path: '/api/account/appointments/:id',
  schema: AccountAppointment,
  searchParams: {} as Partial<API.AccountBookingRequest>,
}).extend('cancel', {
  path: '/api/account/appointments/:id/cancel',
  method: 'POST',
  body: {},
  schema: CancelAppointmentResponse,
})

const AccountResource = {
  ...AccountBaseResource,
  me,
  token,
  sso,
  logout: signout,
  register,
  registered,
  partialUpdate,
  activate,
  password: {
    forgot: forgotPassword,
    reset: passwordReset,
    verify: verifyPasswordCode,
  },
  passwordReset,
  updateProfilePhoto,
  personalDetails: {
    get: getPersonalDetails,
    update: updatePersonalDetails,
  },
  reservation: { ...AccountReservations },
  appointment: { ...AccountAppointments },
  event: {
    ...AccountEvents
  },
  schedule,
}

type BookingRequest = {
  startDate?: Date | string
  status?: API.AccountBookingStatus | API.AccountBookingStatus[]
} & API.Pagination

export { AccountResource, type BookingRequest }
