import { FC, PropsWithChildren, ReactNode, memo } from 'react'

import { Col, Divider, Flex, Row, Card as Wrapped } from 'antd'

import sanitize from 'sanitize-html'

import Text from '..//text/Text'
import { useColor } from '../app'
import { Image } from '../image/Image'
import { Skeleton } from '../skeleton/Skeleton'
import { Tags } from '../tag/Tag'
import { Title } from '../text/Title'
import { CardBadge } from './CardBadge'

const Cover = memo(
  ({
    Component = Image,
    badge,
    ...props
  }: SDK.Components.ImageProps &
    Data.Loadable & {
      Component?: FC<SDK.Components.ImageProps>
      badge?: ReactNode
    }) => {
    const { token } = useColor()
    const height = Math.floor((token.size * 24 * 9) / 16)

    return (
      <div
        style={{
          height,
          width: `calc(100% - 2px)`,
          boxShadow: 'none',
          margin: '1px 1px 0',
          borderTopRightRadius: token.borderRadius,
          borderTopLeftRadius: token.borderRadius,
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '0 16px 0 24px',
            position: 'absolute',
            top: Math.floor(Number(height) - (64 * 3) / 4 - 4),
            zIndex: 2,
          }}
        >
          {badge && <CardBadge>{badge}</CardBadge>}
        </div>
        <Component
          style={{ borderTopRightRadius: token.borderRadius, borderTopLeftRadius: token.borderRadius, border: 'none' }}
          height={height}
          {...props}
        />
      </div>
    )
  },
)

const TitleSection = memo(({ title, loading }: { title?: ReactNode; loading?: boolean }) =>
  title && typeof title === 'string' ? (
    <Title translate={'no'} loading={loading} level={5}>
      {title}
    </Title>
  ) : (
    title
  ),
)

const SubtitleSection = memo(({ subTitle, loading }: { subTitle?: ReactNode; loading?: boolean }) =>
  subTitle && typeof subTitle === 'string' ? (
    <Text translate={'no'} font={{ weight: 600 }} loading={loading} level={5}>
      {subTitle}
    </Text>
  ) : (
    subTitle
  ),
)

const TagsSection = memo(
  ({ tags, loading }: { tags?: SDK.Components.CardProps['tags']; loading?: boolean }) =>
    tags &&
    (loading ? (
      <Skeleton width={50} />
    ) : (
      <Tags
        data={tags.map((t) => ({
          id: t.id,
          title: t.name,
        }))}
      />
    )),
)

const DescriptionSection = memo(({ description, loading }: { description?: ReactNode; loading?: boolean }) =>
  loading ? (
    <Flex vertical>
      <Skeleton count={1} width={'75%'} height={14} />
      <Skeleton count={1} width={'65%'} height={14} />
    </Flex>
  ) : (
    <Text type={'secondary'} translate={'no'} rows={3}>
      {typeof description === 'string'
        ? sanitize(description, {
            allowedTags: [],
            parser: { decodeEntities: true },
          })
        : description}
    </Text>
  ),
)

const Card: FC<PropsWithChildren<Partial<SDK.Components.CardProps>>> = ({
  style,
  title,
  subTitle,
  description,
  defaultTitle = '',
  meta,
  loading = false,
  hideDescription = false,
  type = 'primary',
  onClick = undefined,
  active = true,
  image,
  media,
  badge = null,
  bordered = true,
  children,
  className = '',
  shape = 'circle',
  autoHeight = true,
  footer,
  tags,
  ...props
}) => {
  const { token } = useColor()
  return (
    <Wrapped
      className={onClick ? 'hoverable' : ''}
      onClickCapture={onClick}
      bordered={bordered}
      hoverable={false}
      styles={{
        body: {
          minHeight: autoHeight ? `calc(100% - 216px)` : undefined,
          // height: '100%',
          padding: 24,
          display: autoHeight ? 'flex' : undefined,
          backgroundColor: token.cardBg,
          // borderRadius: `0 0 ${token.borderRadius}px ${token.borderRadius}px`,
        },
        ...props.styles,
      }}
      cover={
        typeof image === 'object' ? (
          <Cover height={216} badge={badge} loading={loading} Component={Image} {...image} />
        ) : (
          image
        )
      }
      style={{
        width: '100%',
        height: autoHeight ? '100%' : undefined,
        cursor: onClick ? 'pointer' : 'initial',
        boxShadow: 'none',
        ...style,
        borderColor: `${token.colorBorder}`,
      }}
      {...props}
    >
      <Flex
        gap={12}
        vertical
        align={'stretch'}
        justify={type === 'primary' ? 'start' : 'center'}
        style={{
          width: '100%',
          // height: '100%',
          // justifyContent: 'space-between',
        }}
      >
        <Flex gap={8} vertical justify={'space-between'} style={{ height: '100%' }}>
          <TitleSection title={title} loading={loading} />
          <SubtitleSection subTitle={subTitle} loading={loading} />
          <TagsSection tags={tags} loading={loading} />
          <DescriptionSection description={description} loading={loading} />
          {meta && (loading ? <Skeleton width={'100%'} height={token.fontSizeSM} /> : meta)}
          {footer && <Divider style={{ margin: '8px 0' }} type={'horizontal'} />}
        </Flex>
        <Flex gap={8} vertical align={'bottom'} wrap={false} style={{ marginBottom: 0 }}>
          {children && (loading ? <Skeleton width={'100%'} height={token.fontSizeSM} /> : children)}
          {footer && (loading ? <Skeleton height={token.controlHeight} width={'100%'} /> : footer)}
        </Flex>
      </Flex>
    </Wrapped>
  )
}

const CardMemo = memo(Card)
export { CardMemo as Card }
