import {
  FC,
  Fragment,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Flex, Form, FormInstance, FormProps, Input } from 'antd'

import { AsyncBoundary, useSuspense } from '@data-client/react'

import { Country as CountryWrapper } from 'src/components/address/AddressForm'
import { Button as ButtonWrapper } from 'src/components/button/Button'
import { MembershipCardResource } from 'src/datasource/membership'
import useChangeTracker from 'src/hooks/useChangeTracker'

import { HelpText, Item, Label } from '../form'
import BillingUse from '../form/BillingUse'
import CheckboxCardGroup from '../form/checkbox/CheckboxCardGroup'
import IvyIcon from '../icon'
import { OverlayLoader, SectionLoader } from '../loader'
import { Money, SmallText } from '../text'

type Props = {
  initialValues?: Partial<SDK.Components.CreditCardFormValues>
  loading?: boolean
  onValidate?: (valid: boolean) => void
} & PropsWithChildren<FormProps<SDK.Components.CreditCardFormValues>>

const Context = createContext<{
  initialValues?: Partial<SDK.Components.CreditCardFormValues>
  form: FormInstance<Partial<SDK.Components.CreditCardFormValues>>
  loading: boolean
  valid: boolean
}>({
  initialValues: {},
  form: {} as FormInstance<SDK.Components.CreditCardFormValues>,
  loading: false,
  valid: false,
})

const SubscriptionPicker: FC<{ walletId?: Data.ID }> = ({ walletId }) => {
  const data = useSuspense(MembershipCardResource.getList, { type: ['subscription'], status: ['active', 'failed'] })
  const options = data.filter((m) => m.subscriptionId)

  const initialValues = options.filter((item) => item.walletId === walletId).map((item) => item.id)

  return options ? (
    <Item
      label={
        <Flex vertical style={{ width: '100%', marginBottom: 8 }}>
          <Label strong size={14}>
            Choose membership
          </Label>

          <HelpText
            helperText={
              'The above payment method will be used to process billing charges associated with the Membership subscription plan(s) selected below.'
            }
          />
        </Flex>
      }
    >
      <Flex vertical gap={16}>
        <CheckboxCardGroup
          name={'memberships'}
          checkAll={'Update all subscriptions and memberships with this payment method.'}
          items={options.map((item) => ({
            title: item.title,
            description: (
              <Money suffix={`/ ${item.period}`} currency={item.currency}>
                {item.rate}
              </Money>
            ),
            avatar: {
              background: 'ghost',
              icon: <IvyIcon color={'primary'} type={'application/wallet'} />,
            },
            value: item.id,
            disabled: item.walletId === walletId,
          }))}
        />
      </Flex>
    </Item>
  ) : null
}

const BillingUseCards: FC<{ updateSubscriptions?: boolean }> = ({ updateSubscriptions = true }) => {
  const { initialValues, form } = useContext(Context)
  const watch = Form.useWatch('billingUse', form)
  return (
    <Flex vertical gap={16} style={{ width: '100%' }}>
      <BillingUse initialValue={initialValues?.billingUse} gridSize={3} />
      {updateSubscriptions && watch === 'billing' && (
        <AsyncBoundary fallback={<SectionLoader />}>
          <SubscriptionPicker walletId={initialValues?.id} />
        </AsyncBoundary>
      )}
    </Flex>
  )
}

const FullName: FC = () => (
  <Item
    rules={[{ required: true, message: 'Please enter your full name' }]}
    name={['billingDetails', 'name']}
    tooltip={'Enter your first and last name exactly as they appear on your billing statement'}
    label={'Full name'}
  >
    <Input />
  </Item>
)

const Country: FC = () => (
  <Item
    required
    rules={[
      {
        required: true,
        message: 'Please select a country',
      },
    ]}
    name={['billingDetails', 'country']}
    label={'Country'}
  >
    <CountryWrapper.Select />
  </Item>
)

// Need to set the default value
const Expiration: FC = () => {
  const { form } = useContext(Context)

  const resolveValue = useCallback((value: string) => {
    value = value.replace('/', '').replaceAll(' ', '')
    const length = value.length
    let newDate = value
    let month: string | undefined
    let year: string | undefined

    if (length > 0) {
      month = value.slice(0, 2)
      year = value.slice(2, length > 4 ? 4 : length)

      if ((parseInt(month) < 10 && parseInt(month) > 1) || parseInt(month) > 12) {
        if (month.charAt(0) === '0') {
          month = `0${month.charAt(1)}`
          year = value.slice(2, length > 3 ? 4 : length)
        } else {
          month = `0${month.charAt(0)}`
          year = value.slice(1, length > 3 ? 3 : length)
        }
      } else {
        year = value.slice(2, length > 4 ? 4 : length)
      }

      if (month.length < 2) {
        newDate = `${month}`
      } else {
        newDate = `${month} / ${year}`
      }
    }

    return {
      cardExpiry: newDate,
      expMonth: month ? parseInt(month) ?? undefined : undefined,
      expYear: year ? parseInt(year) ?? undefined : undefined,
    }
  }, [])

  const formatExpiration = useCallback(
    (value: string) => {
      const { cardExpiry, expMonth, expYear } = resolveValue(value)
      form.setFieldsValue({ cardExpiry, expMonth, expYear })
      return cardExpiry
    },
    [form, resolveValue],
  )

  const handleChange = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, currentTarget } = evt
      const { selectionStart, value } = currentTarget
      if (key === 'Backspace' && selectionStart === 4 && value[2] === ' ') {
        evt.preventDefault()
        const newValue = value.slice(0, 2)
        form.setFieldsValue({ cardExpiry: newValue })
        return
      } else {
        formatExpiration(value)
      }
    },
    [form, formatExpiration],
  )

  // Set the initial cardExpiry expiration date value based on the expMonth and expYear
  useEffect(() => {
    const expMonth = form.getFieldValue('expMonth') ?? ''
    const expYear = form.getFieldValue('expYear') ?? ''

    const { cardExpiry } = resolveValue(`${expMonth}${expYear}`)
    form.setFieldsValue({ cardExpiry })
  }, [form])

  return (
    <Fragment>
      <Item
        name={'cardExpiry'}
        label={'Exp Date'}
        rules={[
          {
            required: true,
            validator: (_, value) => {
              const { expMonth, expYear } = resolveValue(value)
              if (!expMonth || !expYear) {
                return Promise.reject('Invalid expiration date')
              } else {
                const currentYear = new Date().getFullYear().toString().slice(2)
                const nextMonth = new Date().getMonth() + 1
                if (expYear < parseInt(currentYear) || (expYear === parseInt(currentYear) && expMonth < nextMonth)) {
                  return Promise.reject('Card has expired')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input onKeyUp={handleChange} style={{ width: '100%' }} placeholder={'MM/YY'} />
      </Item>
      <Item name={'expMonth'} hidden>
        <Input />
      </Item>
      <Item name={'expYear'} hidden>
        <Input />
      </Item>
    </Fragment>
  )
}

const ZipCode: FC = () => <CountryWrapper.ZipCode name={['billingDetails', 'zipCode']} />

const Nickname: FC = () => (
  <Item
    name={'nickname'}
    label={'Nickname'}
    tooltip={'Enter a nickname to easily identify this payment method in the future'}
  >
    <Input />
  </Item>
)

const Button: FC<Omit<SDK.Components.ButtonProps, 'onClick'>> = ({ ...props }) => {
  const { form } = useContext(Context)
  const [hasChanged] = useChangeTracker(form)
  const billingUse = Form.useWatch(['billingUse'], form)
  const memberships = Form.useWatch(['memberships'], form)

  const alert = useMemo(() => {
    if (billingUse === 'billing' && memberships?.length === 0) {
      return <SmallText type={'warning'}>Select a membership to use with this payment method.</SmallText>
    }
    return null
  }, [billingUse, memberships])

  const enabled = useMemo(() => {
    if (billingUse === 'billing') {
      return memberships?.length > 0
    }
    return hasChanged
  }, [hasChanged, billingUse, memberships])

  return (
    <Flex vertical gap={8}>
      <ButtonWrapper block type={'primary'} onClick={() => form.submit()} {...props} disabled={!enabled} />
      {alert}
    </Flex>
  )
}

const CreditCardForm: FC<Props> & {
  BillingUseCards: FC
  FullName: FC
  Country: FC
  ZipCode: FC
  Nickname: FC
  Expiration: FC
  Button: FC<Omit<SDK.Components.ButtonProps, 'onClick'>>
} = ({ initialValues, children, loading = false, onFinish, form: defaultForm, onValidate, ...props }) => {
  const [valid, setValid] = useState(false)
  const [form] = Form.useForm(defaultForm)
  const values = Form.useWatch([], form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setValid(true))
      .catch(() => setValid(false))
  }, [form, values])

  useEffect(() => {
    onValidate?.(valid)
  }, [valid])

  return (
    <Context.Provider
      value={{
        initialValues,
        form,
        loading,
        valid,
      }}
    >
      <OverlayLoader loading={loading}>
        <Form<SDK.Components.CreditCardFormValues>
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={initialValues}
          {...props}
        >
          <CountryWrapper form={form}>{children}</CountryWrapper>
        </Form>
      </OverlayLoader>
    </Context.Provider>
  )
}

// CreditCardForm.BillingUse = BillingUse
CreditCardForm.BillingUseCards = BillingUseCards
CreditCardForm.FullName = FullName
CreditCardForm.Country = Country
CreditCardForm.ZipCode = ZipCode
CreditCardForm.Nickname = Nickname
CreditCardForm.Expiration = Expiration
CreditCardForm.Button = Button

export { CreditCardForm }
