import { ApiEndpoint, createApiResource } from '../api/endpoint'
import { ApiEntity } from '../api/entity'
import { BusinessLocation } from '../business'
import { ShippingAddressEntity } from '../shipping'
import { SurveyEntity } from '../survey'
import { TransactionEntity } from '../transaction'
import { OrderItem } from './orderItem'
import { OrderStatus } from './types'

class OrderEntity extends ApiEntity implements Data.Transactional {
  static schema = {
    shippingAddress: ShippingAddressEntity,
    items: [OrderItem],
  }
  readonly paidDate: string = ''
  readonly createdOn: Date = new Date()
  readonly data: string = ''
  readonly note: string = ''
  readonly status: OrderStatus = 'processing'
  readonly charge: number = 0.0
  readonly serviceFeeTotal: number = 0
  readonly shippingTotal: number = 0
  readonly discountTotal: number = 0
  readonly currency?: string
  readonly subTotal: number = 0.0
  readonly tipTotal: number = 0.0
  readonly taxTotal: number = 0.0
  readonly total: number = 0.0
  readonly type: string = ''
  readonly via: string = ''
  readonly quantity: number = 0
  readonly shippingAddress?: ShippingAddressEntity
  readonly items: OrderItem[] = [OrderItem.fromJS()]
  readonly surveys?: SurveyEntity[]
  readonly businessLocation?: BusinessLocation

  get hasProducts(): boolean {
    return this.items.some((o) => o.type === 'product')
  }

  get hasTickets(): boolean {
    return this.items.some((o) => o.type === 'event')
  }

  get hasReservations(): boolean {
    return this.items.some((o) => o.type === 'reservation')
  }

  get hasAppointments(): boolean {
    return this.items.some((o) => o.type === 'appointment')
  }

  has(type: OrderType): boolean {
    return this.items.some((o) => o.type === type)
  }
}

const OrderResourceBase = createApiResource({
  path: '/api/account/orders/:id',
  schema: OrderEntity,
})

const transactions = new ApiEndpoint({
  path: '/api/account/orders/:id/transactions',
  method: 'GET',
  schema: [TransactionEntity],
})

const OrderResource = {
  ...OrderResourceBase,
  transactions,
}

export { OrderEntity, OrderResource }
