import { FC, useEffect, useState } from 'react'

import { Flex } from 'antd'

import { useLoading } from '@data-client/hooks'

import { Button } from '@peoplevine/sdk/components/button/Button'
import { CheckableGroup } from '@peoplevine/sdk/components/checkable'
import { HorizontalSpace, VerticalSpace } from '@peoplevine/sdk/components/layout'
import { useResponsive } from '@peoplevine/sdk/components/screen/Breakpoint'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { AccountIsRegistered } from '@peoplevine/sdk/datasource/account/account'
import { type VerifyMethod } from '@peoplevine/sdk/datasource/verify'
import { useFeatureToggles } from '@peoplevine/sdk/providers/Feature'

import { useActivate } from './ActivateProvider'
import VerifySMSCode from './verify/VerifySmsCode'

const SendAccountActivation: FC<AccountIsRegistered> = ({ customerId, firstName, mobileNumber, email }) => {
  const { isMobile } = useResponsive()
  const { hasSms } = useFeatureToggles()
  const { sendVerification, activationSent, restartActivation } = useActivate()
  const [method, setMethod] = useState<VerifyMethod>(!hasSms ? 'email' : 'sms')

  useEffect(() => {
    !mobileNumber && setMethod('email')
  }, [mobileNumber])

  const [sendActivationLink, loading] = useLoading(sendVerification, [])

  return activationSent ? (
    activationSent === 'sms' ? (
      <VerifySMSCode customerId={customerId} />
    ) : (
      <VerticalSpace size={16}>
        <Title level={1} style={{ marginBottom: 0 }}>
          Activation Link Sent
        </Title>
        <Flex vertical gap={2}>
          <Text context={{ email }}>{"We've sent an activation link to {email}"}</Text>
          <Text>Click the link in the email to setup a new password</Text>
          <Flex gap={2} align={'center'}>
            <Text>Having Trouble?</Text>
            <Button style={{ width: 'auto' }} onClick={restartActivation} type={'link'}>
              Resend activation code
            </Button>
          </Flex>
        </Flex>
      </VerticalSpace>
    )
  ) : (
    <VerticalSpace size={'large'}>
      <VerticalSpace>
        <Title level={1} style={{ marginBottom: 0 }}>
          {'Activate Account'}
        </Title>
        <Text context={{ firstName }}>{'Welcome to your membership account, {firstName}!'}</Text>
        <Text type={'secondary'}>
          {hasSms
            ? `Select a method below to begin the activation process`
            : `Before accessing your account we need to verify your email address`}
        </Text>
      </VerticalSpace>

      <VerticalSpace size={'large'}>
        <CheckableGroup<VerifyMethod>
          defaultValue={method}
          onChange={(value) => value && setMethod(value)}
          items={[
            {
              title: (
                <Text strong>
                  <HorizontalSpace align={'center'}>
                    <Text>SMS Text Message</Text>
                    {mobileNumber && (
                      <Text type={'secondary'} translate={'no'}>
                        ({mobileNumber.replaceAll('*', '•')})
                      </Text>
                    )}
                  </HorizontalSpace>
                </Text>
              ),
              description: <Text>Receive a text message with an activation code</Text>,
              value: 'sms',
              icon: !isMobile ? 'application/mobile' : undefined,
              hidden: !mobileNumber || !hasSms,
            },
            {
              title: (
                <Text strong>
                  <HorizontalSpace align={'center'}>
                    <Text>Email</Text>
                    {email && (
                      <Text size={'small'} translate={'no'} type={'secondary'}>
                        ({email})
                      </Text>
                    )}
                  </HorizontalSpace>
                </Text>
              ),
              description: <Text>Receive an email with an activation link</Text>,
              value: 'email',
              icon: !isMobile ? 'application/mail' : undefined,
            },
          ]}
        />
        <Button loading={loading} onClick={() => sendActivationLink({ customerId, method })} block disabled={!method}>
          Continue
        </Button>
      </VerticalSpace>
    </VerticalSpace>
  )
}

export default SendAccountActivation
