import ApiEntity from '../../api/entity'
import { OrderEntity } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountBooking } from './booking'

class AccountReservation extends AccountBooking {
  readonly type: API.ScheduleType = 'reservation'
}

class CancelReservationResponse extends ApiEntity implements API.CancelBookingResponse {
  static schema = {
    order: OrderEntity,
    refunds: [TransactionRefundItem],
    booking: AccountReservation,
  }
  orderId?: Data.ID
  order?: OrderEntity
  refunds?: TransactionRefundItem[]
  amount: number
  message: string
  success: boolean
  booking?: AccountReservation
}

export { AccountReservation, CancelReservationResponse }
