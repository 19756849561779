import { CustomerEntity } from './account/account'
import { CreatePaymentEndpoint } from './api/PaymentEndpoint'
import { ApiEndpoint, createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'
import { Passbook } from './passbook'
import { PaymentMethod } from './payment'
import { ScanData } from './scanData'
import { SubscriptionEntity, SubscriptionFrequency } from './subscription'
import { ChargeResponse, TransactionEntity } from './transaction'

export type MembershipStatusType = 'active' | 'inactive'

class MembershipEntity extends ApiEntity {
  static urlRoot = `/api/memberships`
  readonly id: string = ''
  readonly status: MembershipStatusType = 'active'
  readonly summary: string = ''
  readonly title: string = ''
  readonly details: string = ''
  readonly type: API.MembershipType = ''
  readonly backgroundColor: string = ''
  readonly foregroundColor: string = ''
  readonly surveyId: number = 0
}

export type MembershipCardStatus = '' | 'active' | 'cancelled' | 'expired' | 'failed' | 'pending' | 'archived'

class MembershipCardEntity extends ApiEntity implements Data.Scannable {
  static urlRoot = `/api/account/memberships`
  static schema = {
    customer: CustomerEntity,
    subscription: SubscriptionEntity
  }
  readonly membershipId: number = 0
  readonly customerId: number = 0
  readonly cardId: number = 0
  readonly status: MembershipCardStatus = ''
  readonly expires: boolean = true
  readonly expirationDate: Date = new Date(0)
  readonly maxInvites: number = 0
  readonly remainingInvites: number = 0
  readonly canInvite: boolean = false
  readonly scanData: ScanData = new ScanData()
  readonly passbook: Passbook = Passbook.fromJS()
  readonly title: string = ''
  readonly summary?: string
  readonly parentCardId: number = 0
  readonly subscriptionId: number = 0
  readonly type?: API.MembershipType
  readonly walletId: number = 0
  readonly customer: Omit<CustomerEntity, 'isMember'> = CustomerEntity.fromJS()
  readonly currency?: string
  readonly rate?: number
  readonly frequency?: SubscriptionFrequency
  readonly theme: {
    backgroundColor: string
    foregroundColor: string
    logo?: string
  } = {
      backgroundColor: '',
      foregroundColor: '',
    }

  get isSubMember(): boolean {
    return this.parentCardId || this.parentCardId !== 0
  }

  get period(): string {
    switch (this.frequency) {
      case 'daily':
        return 'day'
      case 'weekly':
        return 'week'
      case 'monthly':
        return 'month'
      case 'quarterly':
        return 'quarter'
      case 'semi':
        return '6 months'
      case 'annually':
        return 'year'
      default:
        return ''
    }
  }
}

export type SubMemberCreateProps = Pick<CustomerEntity, 'id' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'profilePhoto'>

type MembershipCardSendResponse = {
  success: boolean
  message: string
}

class SubMembershipCard extends ApiEntity {
  static schema = {
    customer: CustomerEntity,
  }
  readonly id: Data.ID = 0
  readonly status: MembershipCardStatus = ''
  readonly customerId: number = 0
  readonly graphic: string = ''
  readonly title: string = ''
  readonly type?: API.MembershipType
  readonly membershipId: number = 0
  readonly subscriptionId?: number
  readonly cardId: string = ''
  readonly serial: string = ''
  readonly parentCardId: number = 0
  readonly barcode: string = ''
  readonly passbook: Passbook = Passbook.fromJS()
  readonly expires: boolean = false
  readonly expirationDate: Date = new Date()
  readonly createdOn: Date = new Date()
  readonly customer: Omit<CustomerEntity, 'isMember'> = CustomerEntity.fromJS()
}

const MembershipCardBase = createApiResource({
  path: '/api/account/memberships/:id',
  schema: MembershipCardEntity,
  searchParams: {} as { type?: API.MembershipType[] }
}).extend('partialUpdate', {
  method: 'PATCH',
  body: {} as { walletId: Data.ID },
})

const send = new ApiEndpoint({
  path: '/api/account/memberships/:id/send',
  method: 'POST',
  body: {} as {
    action: 'email' | 'sms',
    callback?: {
      uri: string,
      additionalParams?: Record<string, string>
    }
  },
  schema: {} as MembershipCardSendResponse,
})

const subscription = new ApiEndpoint({
  path: '/api/account/memberships/:id/subscription',
  method: 'GET',
  schema: SubscriptionEntity,
})

const payments = new ApiEndpoint({
  path: '/api/account/memberships/:id/payments',
  method: 'GET',
  schema: [TransactionEntity],
})

const pay = CreatePaymentEndpoint({
  path: '/api/account/memberships/:id/payments',
  body: {} as PaymentMethod,
  schema: ChargeResponse,
})

const updateSubMember = new ApiEndpoint({
  path: '/api/account/memberships/:parentCardId/members/:id',
  method: 'PATCH',
  body: {} as SubMemberCreateProps,
  schema: SubMembershipCard,
})

const SubMembershipCardResource = createApiResource({
  path: `/api/account/memberships/:parent_card_id/members/:id`,
  schema: SubMembershipCard,
}).extend('create', {
  body: {} as SubMemberCreateProps,
})

const removeSubMember = new ApiEndpoint({
  path: '/api/account/memberships/:parentCardId/members/:id',
  method: 'DELETE',
  schema: SubMembershipCard,
})

const MembershipCardResource = {
  ...MembershipCardBase,
  send,
  subscription,
  payments,
  pay,
}

export { MembershipCardEntity, MembershipCardResource, MembershipEntity, SubMembershipCard, SubMembershipCardResource }

