import { lazy, Suspense } from 'react'

import { Col, Input, Row, Tabs } from 'antd'

import { useLoading } from '@data-client/hooks'

import Form, { Button, Item } from '../form'
import { OverlayLoader, Spinner } from '../loader'
import { Title } from '../text/Title'
import { useDeveloperAuth } from './DebugAuth'

const DebugCompany = lazy(() => import('./DebugCompany'))
const DebugApp = lazy(() => import('./DebugApp'))
const DebugConfig = lazy(() => import('./DebugConfig'))
const DebugLoginConfig = lazy(() => import('./DebugLoginConfig'))
const DebugProps = lazy(() => import('./DebugProps'))

const tabs = [
  {
    label: 'Dev',
    key: 'company',
    Component: DebugCompany,
  },
  {
    label: 'Company Settings',
    key: 'config',
    Component: DebugConfig,
  },
  {
    label: 'Theme',
    key: 'theme',
    Component: DebugProps,
  },
  {
    label: 'Login',
    key: 'login',
    Component: DebugLoginConfig,
  },
  {
    label: 'App',
    key: 'app',
    Component: DebugApp,
  },
]

const DeveloperWindow = () => {
  const { authenticated, authenticate } = useDeveloperAuth()

  const [onFinish, loading] = useLoading(
    (credentials: API.LoginRequest<'password'>) => {
      return authenticate(credentials.username, credentials.password)
    },
    [authenticate],
  )

  return (
    <OverlayLoader loading={loading}>
      {!authenticated ? (
        <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
          <Col>
            <Title level={3} style={{ paddingBottom: 30 }}>
              Admin Login
            </Title>
            <Form onFinish={onFinish}>
              <Row gutter={[0, 24]}>
              <Col span={24}>
              <Item name={'username'} label={'Username or Email'}>
                <Input required />
              </Item>
              </Col>
              <Col span={24}>
              <Item name={'password'} label={'Password'}>
                <Input.Password required />
              </Item>
              </Col>
              <Col span={24}>
              <Button loading={loading} block htmlType={'submit'}>
                Login
              </Button>
              </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Tabs
          items={tabs.map(({ Component, key, ...props }) => ({
            children: (
              <Suspense fallback={<Spinner fullscreen />}>
                <Component />
              </Suspense>
            ),
            key,
            ...props,
          }))}
        />
      )}
    </OverlayLoader>
  )
}

export default DeveloperWindow
