import { SizeType } from 'antd/es/config-provider/SizeContext'

import camelcaseKeys from 'camelcase-keys'
import { extend } from 'dayjs'

import { ColorKeys } from '../themes/default'
import { ApiEndpoint } from './api/endpoint'
import { ApiEntity, ApiObject, Singleton } from './api/entity'
import { CompanyConfig } from './company'

class PageConfig extends ApiObject {
  readonly name: string = ''
  readonly uri: string = ''
  readonly icon?: string = ''
  readonly membersOnly: boolean = true
  readonly enabled: boolean = true
  readonly banners: boolean = false
  readonly nav?: boolean = true
  readonly order: number = 0
}

class IntlConfig extends ApiObject {
  readonly rtl: boolean = false
  readonly currency: string = 'USD'
  readonly locale: string = 'en-US'
  readonly timezone: string = 'UTC'
  readonly messages: { [key: string]: { [key: string]: string } } = { en: {} }

  get currencySymbol(): string {
    const currency = this.currency
    return (0)
      .toLocaleString(this.locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
      .replace(/\d/g, '')
      .trim()
  }

  get country(): string {
    const value = this.locale.split('-')
    return value.length > 1 ? value[1] : this.locale
  }

  get language(): string {
    const value = this.locale.split('-')
    return value.length > 1 ? value[0] : this.locale
  }

  get defaultMessages(): Record<string, string> {
    return this.messages[this.language]
  }

  get dateFormat(): string {
    return this.locale.toLocaleLowerCase() === 'en-us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
  }
}

export type ProcessorCompany =
  | ''
  | 'authorize.net'
  | 'authorize.netv2'
  | 'braintree'
  | 'cardconnect'
  | 'gocardless'
  | 'networkinternational'
  | 'paya'
  | 'slimcd'
  | 'squarepos'
  | 'stripe'
  | 'stripev2'
  | 'toast'
  | 'wepay'

export type ProcessorIntegration = 'plaid'

type ProcessorIntegrationConfig = {
  name: ProcessorIntegration
  key: string
}

class ProcessorConfig extends ApiEntity {
  readonly id: number = 0
  readonly acct?: string
  readonly name: ProcessorCompany = ''
  readonly uri: string = ''
  readonly key: string = ''
  readonly cardsAccepted: Design.PaymentBrandIcon[] = []
  readonly acceptAch: boolean = false
  readonly default: boolean = false
  readonly integration?: ProcessorIntegrationConfig

  get isStripe() {
    return this.name === 'stripe' || this.name === 'stripev2'
  }
}

export type SocialItem = {
  title: string
  icon: string
  link: string
}

type AnalyticsProps = {
  google?: string
}

type SocialMetaProps = {
  readonly title: string
  readonly image: string
  readonly video: string
}

type SocialProps = {
  readonly facebook: string
  readonly google: string
  readonly twitter: string
  readonly linkedIn: string
  readonly instagram: string
  readonly meta: SocialMetaProps
}

type LayoutImageItem = {
  readonly src: string
  readonly width: number
  readonly justify: 'left' | 'right' | 'center'
}

type LoginLogo = {
  desktop?: LayoutImageItem
  mobile?: LayoutImageItem
}

interface LayoutProps {
  theme: SDK.Components.ColorMode
  basicSize: SizeType
  loginLogo: LoginLogo
  pdfLogo: string
  footer: boolean
}

class NavigationLogo extends ApiObject {
  justify: 'left' | 'right' | 'center' = 'left'
  expanded: SDK.Components.LogoType = 'icon+text'
  collapsed: SDK.Components.LogoType = 'icon'
}

type FooterProps = {
  readonly hidden: boolean
}

type LogoSrcProps = {
  readonly text: string
  readonly icon: string
  readonly full: string
  readonly fullSize?: number
  readonly textSize?: number
  readonly iconSize?: number
}

type FontItem = {
  name: string
  url: string
}

type GuestPassSettings = {
  requireEmail?: boolean
  requirePhone?: boolean
}

type PortalSettings = {
  enableRegistration?: boolean
  showNominations?: boolean
  showFaq?: boolean
  guestPass: GuestPassSettings
  guestDirectory: boolean
  globalNetwork: GlobalNetwork
}

type PortalFeatures = {
  surveys: {
    nomination: boolean
    personalDetails: boolean
    contact: boolean
    guestPass: boolean
    registration: boolean
  }
  editPrimaryInfo?: boolean
  sms: boolean
  appId?: number
}

type PortalAssets = {
  fonts?: string[]
}

interface PortalComponent {
  readonly title: string
  readonly enabled: boolean
}

interface PortalAccountSection {
  readonly components: {
    memberships: PortalComponent
    profile: PortalComponent
    wallet: PortalComponent
    billing: PortalComponent
    contact: PortalComponent
  }
}

interface PortalSections {
  account: PortalAccountSection
}

interface LogoProps {
  standard?: LogoSrcProps
  inverted?: LogoSrcProps
}

interface FontProps {
  mainFont?: FontItem
  secondaryFont?: FontItem
}

interface NavigationLogoBreakpoint {
  justify?: 'left' | 'center' | 'right'
  desktop: NavigationLogo
  mobile: NavigationLogo
}

class NavigationProps extends ApiObject {
  readonly mode: 'vertical' | 'horizontal' | 'burger' = 'vertical'
  readonly theme: SDK.Components.ColorMode = 'dark'
  readonly logo: NavigationLogoBreakpoint = {} as NavigationLogoBreakpoint
}

class SiderProps extends ApiObject {
  readonly width: number = 280
  readonly collapsedWidth: number = 112
  readonly theme: SDK.Components.ColorMode = 'dark'
  readonly hidden: boolean = false
}

class SloganProps {
  readonly title: string = ''
  readonly subtitle: string = ''
  readonly image?: string
  readonly mobile?: boolean
  readonly mobileOnly?: boolean
}

class PropsConfig extends Singleton {
  readonly layout: LayoutProps = {} as LayoutProps
  readonly navigation: NavigationProps = NavigationProps.fromJS()
  readonly sider: SiderProps = SiderProps.fromJS()
  readonly footer?: FooterProps
  readonly colors: { [key in ColorKeys]?: string | number } = {}
  readonly logo: LogoProps = {} as LogoProps
  readonly social?: SocialProps = {} as SocialProps
  readonly slogans: SloganProps[] = []
  readonly fonts: FontProps = {} as FontProps
  readonly assets: PortalAssets = {} as PortalAssets
  readonly sections: PortalSections = {} as PortalSections
  readonly favicon: string = ''
}

class GlobalNetwork extends ApiEntity {
  readonly enabled: boolean = false
}

type PortalPlan = 'Lounge' | 'Plus' | string

class PortalConfig extends ApiEntity {
  readonly cdn: string = 'https://cdn.qa.peoplevine.dev'
  readonly domainId: number = 0
  readonly companyId: number = 0
  readonly environment: string = ''
  readonly token: string = ''
  readonly key: string = ''
  readonly debug: boolean = false
  readonly plan: PortalPlan = 'Plus'
  readonly analytics: AnalyticsProps = {}
  readonly pages: PageConfig[] = []
  readonly company: CompanyConfig = CompanyConfig.fromJS()
  readonly props: PropsConfig = PropsConfig.fromJS()
  readonly theme: Partial<{ [key in ColorKeys]: string }> = {} as { [key in ColorKeys]: string }
  readonly intl: IntlConfig = IntlConfig.fromJS()
  readonly features?: PortalFeatures
  readonly settings: PortalSettings
  readonly processorId?: number
  readonly processors: ProcessorConfig[] = []
  readonly domainName: string = ''

  get defaultProcessor(): ProcessorConfig | undefined {
    return this.processors.find((p) => p.default)
  }

  get stripeProcessor() {
    return this.processors.find((p) => p.name === 'stripe' || p.name === 'stripev2')
  }

  get achProcessor() {
    return this.processors.find((p) => p.acceptAch && p.name === 'gocardless')
  }

  pk() {
    return `${this.domainId}`
  }

  pageByUri = (uri: string) => {
    const search = uri.startsWith('/') ? uri : `/${uri}`
    return this.pages.find((it) => `${it.uri}` === `${search}`)
  }
}

const handshake = new ApiEndpoint({
  method: 'GET',
  path: '/config',
  schema: PortalConfig,
  process: (value: any) =>
    camelcaseKeys(value, { deep: true, stopPaths: ['portal.intl.messages', 'portal.theme'] as const }) as PortalConfig,
})

const ConfigResource = {
  handshake,
}

export { ConfigResource, IntlConfig, PageConfig, PortalConfig, ProcessorConfig, PropsConfig, SloganProps }
