import { createRoot } from 'react-dom/client'

import './assets/global.scss'
import RootProvider from './containers/RootProvider'
import { initSentry } from './containers/Sentry'

initSentry()

const element = document.querySelector('#root')
if (element) {
  createRoot(element).render(<RootProvider />)
}
