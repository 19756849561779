import dayjs from 'dayjs'

import { AccountBooking } from '../account/booking/booking'
import { AccountTicket } from '../account/ticket'
import { ApiEntity } from '../api/entity'
import { Product } from '../product'
import { SurveyEndpoint } from '../survey'

export type OrderStatus = 'cancelled' | 'completed' | 'pending' | 'processing'

type OrderItemStatus = 'active' | 'cancelled'

type OrderType = '' | 'product' | 'event' | 'appointment' | 'reservation' | 'fandb'

class OrderItem extends ApiEntity implements Data.Imaginated {
  static schema = {
    createdOn: (iso) => dayjs(iso).toDate(),
  }
  readonly externalId: string = ''
  readonly isSubscription: boolean = false
  readonly subscriptionFrequency: string = ''
  readonly orderId: number = 0
  readonly discount?: number
  readonly price: number = 0
  readonly quantity: number = 0
  readonly total: number = 0
  readonly subTotal: number = 0
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly productAttribute: string = ''
  readonly productId: number = 0
  readonly type: OrderType = ''
  readonly subscriptionId: number = 0
  readonly createdOn: Date = new Date()
  readonly shippingTotal: number = 0
  readonly status: OrderItemStatus = 'active'
  readonly survey?: SurveyEndpoint
  readonly booking?: AccountBooking
  readonly ticket?: AccountTicket
  readonly product?: Product

  get image(): string {
    return this.photo
  }

  get placeholder(): Design.PlaceholderIcon {
    switch (this.type) {
      case 'event':
        return 'events'
      case 'reservation':
      case 'appointment':
        return 'rooms'
      case 'product':
      default:
        return 'items'
    }
  }

  get images(): string[] {
    return [this.photo]
  }
}

export { OrderItem }
