import { type FC, lazy } from 'react'
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom'

import Result from '@peoplevine/sdk/components/result'
import { AuthProvider } from '@peoplevine/sdk/providers'
import { PaymentProvider } from '@peoplevine/sdk/providers/payment/Payment'

import {
  errorBoundary,
  guestPolicy,
  membersPolicy,
  menuPagePolicy,
  pathAliases,
  plusPlanPolicy,
  routeEntries,
  routeObject,
} from './decorators'
import { drawerRoutes, guestRoutes, memberRoutes } from './routes'

const Unauthorized = lazy(() => import('src/layouts/Unauthorized'))
const Authorized = lazy(() => import('src/layouts/Authorized'))
const Drawer = lazy(() => import('src/layouts/Drawer'))
const DefaultLayout = lazy(() => import('src/layouts/Default'))

function createRoutesFromConfig(): RouteObject[] {
  return [
    {
      Component: Unauthorized,
      secure: true,
      children: routeEntries(guestRoutes).flatMap(pathAliases).map(routeObject),
    },
    {
      Component: Authorized,
      secure: true,
      children: [
        {
          Component: DefaultLayout,
          children: routeEntries(memberRoutes)
            .map((it) => ({ ...it, membersOnly: true, secured: true }))
            .flatMap(pathAliases)
            .map(plusPlanPolicy)
            .map(membersPolicy)
            .map(menuPagePolicy)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
        {
          Component: Drawer,
          children: routeEntries(drawerRoutes)
            .map((it) => ({ ...it, membersOnly: true, secured: true }))
            .flatMap(pathAliases)
            .map(membersPolicy)
            .map(guestPolicy)
            .map(errorBoundary)
            .map(routeObject),
        },
      ],
    },
    {
      path: '*',
      Component: Result.NotFound,
    },
  ].map(guestPolicy)
}

const RootRoute: FC = () => {
  return (
    <AuthProvider>
      <PaymentProvider>
        <Outlet />
      </PaymentProvider>
    </AuthProvider>
  )
}

// const createRouter = wrapCreateBrowserRouter(createBrowserRouter)

const routes = [{ Component: RootRoute, children: createRoutesFromConfig() }]
const router = createBrowserRouter(routes)
const RoutesProvider = () => <RouterProvider router={router} />
export default RoutesProvider
