import { createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'

type CompanyStatus = 'active' | 'inactive'

export type CompanyFilter = {
  order_by?: string
  search?: string
  status?: CompanyStatus
} & Partial<Data.Paginated>

class CompanyConfig extends ApiEntity implements Data.Named, Data.Address {
  readonly name: string = ''
  readonly logoUrl: string = ''
  readonly iconUrl: string = ''
  readonly biography: string = ''
  readonly website: string = ''
  readonly email: string = ''
  readonly phone: string = ''
  readonly address: string = ''
  readonly address2: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zipCode: string = ''
  readonly country: string = ''
  readonly timezone: string = ''
  readonly timezoneOffset: number = 0
  readonly backgroundColor: string = ''
  readonly fullAddress: string = ''
  type: API.AddressType = 'company'
}

const CompanyBaseResource = createApiResource({
  path: '/api/admin/company/:id',
  schema: CompanyConfig,
})

const CompanyResource = {
  ...CompanyBaseResource,
}

export { CompanyConfig, CompanyResource }
