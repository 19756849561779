import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useCache, useController, useLoading } from '@data-client/react'

import { OverlayLoader } from 'src/components/loader'
import { fetchRefresh, fetchToken, removeToken, setToken } from 'src/services/tokens'

import { AccountResource } from '../api/account'
import { AccountEntity, type AccountFlag, TokenEntity } from '../datasource/account/account'
import { useNotification } from '../providers/Notification'
import { useConfig } from './ConfigProvider'
import { useNativeApp } from './NativeApp'

type ContextProps = {
  authenticate: (credentials: API.LoginRequest<'customer_password'>) => Promise<AccountEntity | null>
  ssoLogin: (token: string) => Promise<AccountEntity | null>
  authenticated: boolean | undefined
  logout: () => void
  user?: AccountEntity | null
  flag?: AccountFlag
}

const defaultValues: ContextProps = {
  authenticate: async () => null,
  ssoLogin: async () => null,
  authenticated: undefined,
  logout: async () => undefined,
}

const Auth = createContext<ContextProps>(defaultValues)

function AuthProvider({ children }: PropsWithChildren) {
  const { invalidateAll, fetch } = useController()
  const { company } = useConfig()
  const { notifyOnError } = useNotification()
  const cached = useCache(AccountResource.me)
  const [user, setUser] = useState<AccountEntity | undefined>(cached)
  const { subscribe, unsubscribe } = useNativeApp()

  const [authenticated, setAuthenticated] = useState<boolean>()

  const fetchAccount = useCallback(async () => {
    return await fetch(AccountResource.me)
      .then(setUser)
      .then(() => setAuthenticated(true))
      .catch(() => setAuthenticated(false))
  }, [fetch])

  const init = useCallback(async () => {
    await fetchToken().then((token) => {
      if (token !== null) {
        fetchAccount().then(() => setAuthenticated(true))
      } else {
        setAuthenticated(false)
      }
    })
  }, [fetchAccount])

  const [logout, loggingOut] = useLoading(async () => {
    const refreshToken = fetchRefresh()
    if (!refreshToken) return
    return await fetch(AccountResource.logout, {
      refreshToken: refreshToken,
    }).then(() => {
      removeToken()
      setAuthenticated(false)
      setUser(undefined)
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOGOUT' }))
      invalidateAll({ testKey: (key) => !key.startsWith('/config') })
    })
  }, [fetch, invalidateAll, removeToken, fetchRefresh])

  const handleAuth = useCallback(
    (token: TokenEntity) => {
      setToken(token)
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'customerNumber', data: token.customer.pk() }))
      setUser(token.customer)
      setAuthenticated(true)
      window.location.reload()
      return token.customer
    },
    [setUser, setAuthenticated],
  )

  const authenticate = useCallback(
    async (credentials: API.LoginRequest<'customer_password'>) => {
      return await fetch(AccountResource.token, {
        ...credentials,
        companyId: company.id,
        grantType: 'customer_password',
      })
        .then(handleAuth)
        .catch((error) => {
          notifyOnError(error)
          setUser(undefined)
          setAuthenticated(false)
          return null
        })
    },
    [company.id, fetch, handleAuth, notifyOnError],
  )

  const ssoLogin = useCallback(
    async (token: string) => {
      return await fetch(AccountResource.sso, {
        accessToken: token,
        grantType: 'sso_token',
        companyId: company.id,
      })
        .then(handleAuth)
        .catch((error) => {
          setUser(undefined)
          setAuthenticated(false)
          throw error
        })
    },
    [company.id, fetch, notifyOnError, handleAuth, setUser],
  )

  useEffect(() => {
    init()

    const id = subscribe({
      event: 'TOKEN',
      callback: (token: Partial<TokenEntity>) => {
        setToken(token)
      },
    })

    return () => {
      unsubscribe(id)
    }
  }, [init, subscribe, unsubscribe])

  return (
    <Auth.Provider
      value={{
        authenticate,
        ssoLogin,
        authenticated,
        user,
        logout,
        flag: user?.flag,
      }}
    >
      <OverlayLoader loading={loggingOut}>{children}</OverlayLoader>
    </Auth.Provider>
  )
}

const SessionProvider = AuthProvider

function useAuth(): ContextProps {
  return useContext(Auth)
}

export { Auth, AuthProvider, useAuth }

export default SessionProvider
