import { FC, PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { useKeyPress, useToggle } from 'react-use'

import { Drawer } from 'antd'

import { useController } from '@data-client/react'

import { DomainResource } from 'src/datasource/domain'

import { DeveloperAuthProvider } from './DebugAuth'
import DeveloperProps from './DebugProps'
import DeveloperWindow from './DeveloperWindow'

type DeveloperProps = {
  switchDomain: (domainName: string) => void
}

const defaultValues: DeveloperProps = {
  switchDomain: () => {},
}

const Develop = createContext(defaultValues)

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const { fetch } = useController()

  const switchDomain = (domainName: string) => {
    fetch(DomainResource.config, { domain: `${domainName}` }).then(() => window.location.reload())
  }

  return (
    <DeveloperAuthProvider>
      <Develop.Provider value={{ switchDomain }}>{children}</Develop.Provider>
    </DeveloperAuthProvider>
  )
}

const DeveloperLayout: FC = () => {
  const [pressed] = useKeyPress('Escape')
  const [active, toggle] = useToggle(false)

  useEffect(() => {
    pressed && toggle()
  }, [pressed, toggle])

  return (
    <DeveloperProvider>
      <Drawer closeIcon={true} open={active} closable={true} onClose={toggle} placement={'right'} size={'large'}>
        <DeveloperWindow />
      </Drawer>
    </DeveloperProvider>
  )
}

const useDeveloper: () => DeveloperProps = () => useContext(Develop)

const DeveloperProvider = ({ children }: PropsWithChildren) => {
  return process.env.NODE_ENV === 'development' ? <Provider>{children}</Provider> : null
}

export { DeveloperLayout, DeveloperProvider, useDeveloper }
