import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useCookie } from 'react-use'

import { Flex, notification } from 'antd'

import { useController } from '@data-client/react'

import { AccountResource } from '@peoplevine/sdk/src/api/account'
import { Link } from '@peoplevine/sdk/src/components/text'
import Text from '@peoplevine/sdk/src/components/text/Text'
import { AccountIsRegistered } from '@peoplevine/sdk/src/datasource/account/account'
import { type VerifyMethod, VerifyResource, VerifyResponse } from '@peoplevine/sdk/src/datasource/verify'
import { useNotification } from '@peoplevine/sdk/src/providers/Notification'
import routes from '@peoplevine/sdk/src/routes'

interface ActivateState {
  customerId?: number
  method?: string | null
  code?: string
}

type ActivateContext = {
  activationSent: string | null
  restartActivation: () => void
  activate: (params: { password: string; code: string; customerId: number }) => Promise<void>
  activateState?: ActivateState
  validateUser: (username: string) => Promise<AccountIsRegistered | undefined>
  sendVerification: (params: { customerId: number; method: VerifyMethod }) => Promise<boolean>
  verifyCode: (params: { code: string; customerId?: number }) => Promise<VerifyResponse | void>
}

const defaultValue: ActivateContext = {
  activationSent: null,
  activate: async (params: { password: string; code: string; customerId: number }) => undefined,
  restartActivation: () => {},
  validateUser: async (username: string) => undefined,
  sendVerification: async (params: { customerId: number; method: VerifyMethod }) => false,
  verifyCode: async (params: { code: string; customerId?: number }) => undefined,
}

const Activate = createContext<ActivateContext>(defaultValue)

const ActivateProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()
  const { notifyOnError, notify } = useNotification()
  const { fetch } = useController()
  const [activationSent, setActivationSent, removeActivationSent] = useCookie('activation-method')
  const [activateState, setActivateState] = useState<ActivateState>({})

  useEffect(() => {
    removeActivationSent()
  }, [])

  const restartActivation = useCallback(() => {
    setActivateState({})
    removeActivationSent()
  }, [removeActivationSent, setActivateState])

  const handleCodeError = useCallback(
    (errorCode: string, errorMessage: string) => {
      let message = 'Invalid Activation Code'
      let description: ReactNode = errorMessage
      if (errorCode === 'activation_code_expired') {
        message = 'Activation Code Expired'
        description = (
          <Flex gap={2} vertical>
            <Text>This activation code is expired.</Text>
            <Link href={routes.userLogin} onClickCapture={restartActivation}>
              Click here to send a new activation code
            </Link>
          </Flex>
        )
      }
      api.warning({
        type: 'warning',
        key: 'activation-code-error',
        message,
        description,
      })
    },
    [navigate, api],
  )

  const validateUser = useCallback(
    async (username: string): Promise<AccountIsRegistered | undefined> => {
      return await fetch(AccountResource.registered, { username: username }).catch(() => undefined)
    },
    [fetch],
  )

  const sendVerification = useCallback(
    async (params: { customerId: number; method: VerifyMethod }): Promise<boolean> => {
      const send = await fetch(VerifyResource.activation.send, {
        ...params,
        callback: `${window.location.origin}/activate`,
      })
        .then((response) => {
          if (response.success) {
            setActivationSent(params.method)
            setActivateState({ ...activateState, customerId: params.customerId, method: params.method })
            api.success({
              type: 'success',
              message: params.method === 'sms' ? 'Activation code sent' : 'Activation Link Sent',
              description: response.message,
            })
          }

          return response
        })
        .catch(notifyOnError)

      return send ? send.success : false
    },
    [fetch, notifyOnError, api],
  )

  const verifyCode = useCallback(
    async (params: { code: string; customerId: number }) => {
      var verify = await fetch(VerifyResource.activation.verify, params)
        .then<VerifyResponse>()
        .then((response) => {
          if (response.success) {
            setActivateState({ ...activateState, code: params.code, customerId: params.customerId })
          } else {
            handleCodeError(response.code, response.message)
          }
          return response
        })
        .catch(notifyOnError)

      return verify
    },
    [fetch, handleCodeError, notifyOnError],
  )

  const activate = useCallback(
    async (params: { password: string; code: string; customerId: number }) => {
      await fetch(AccountResource.activate, {
        code: params.code,
        password: params.password,
        customerId: params.customerId,
      })
        .then(() => {
          restartActivation()
          navigate(routes.userLogin)
          notify({
            type: 'success',
            message: 'Account Activated',
            description:
              'Your account has been successfully activated, you can login to your account using your new password',
          })
        })
        .catch(notifyOnError)
    },
    [fetch, notifyOnError, restartActivation, navigate],
  )

  return (
    <Activate.Provider
      value={{
        activate,
        restartActivation,
        activationSent,
        activateState,
        validateUser,
        sendVerification,
        verifyCode,
      }}
    >
      {children}
      {contextHolder}
    </Activate.Provider>
  )
}

const useActivate: () => ActivateContext = () => useContext(Activate)

export { ActivateProvider, useActivate }
