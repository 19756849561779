import { BaseAddress } from 'src/datasource/address'
import { DateTz } from '../../../api'
import { dayjs } from '../../../services/dayjs'
import { ApiEntity } from '../../api/entity'
import { BusinessLocation } from '../../business'
import { CompanyConfig } from '../../company'
import { TimeSlot } from '../../scheduler'
import { SurveyEntity } from '../../survey'
import { CustomerEntity } from '../account'
import { AccountTicket } from '../ticket'

class AccountBooking extends ApiEntity implements Data.TemporalLocal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
    purchasedTickets: [AccountTicket],
    guest: CustomerEntity,
    owner: CustomerEntity,
  }
  readonly blockType: string = ''
  readonly slot: TimeSlot = TimeSlot.fromJS()
  readonly status: API.AccountBookingStatus = 'booked'
  readonly partySize: number = 0
  readonly canCancel: boolean = false
  readonly cancelable: boolean = false
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly location?: BusinessLocation
  readonly timezoneId: string = ''
  readonly hasSurvey: boolean = false
  readonly surveyId: number = 0
  readonly purchasedTickets: AccountTicket[] = []
  readonly survey?: SurveyEntity
  readonly type: API.BookingItemType = 'appointment'
  readonly company?: CompanyConfig
  readonly guest?: CustomerEntity
  readonly owner?: CustomerEntity

  static get key() {
    return `AccountBooking`
  }

  get qty(): number {
    return this.partySize
  }

  get address(): BaseAddress | undefined {
    return this.location?.address
  }

  get locationName(): string {
    return this.location?.name || ''
  }

  get image(): string {
    return this.photo
  }

  get resolvedType(): API.BookingItemType {
    return this.type as string === 'ticket' ? 'event' : this.type as API.BookingItemType
  }

  // get canCancel(): boolean {
  //   return this.cancelable || this.purchasedTickets.some((t) => t.cancelable)
  // }
}

export { AccountBooking }
