import { type FC, type PropsWithChildren, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { type AnchorLinkProps } from 'antd'

import Text from '..//text/Text'
import { useColor } from '../app'

type LinkProps = Partial<Pick<AnchorLinkProps, 'target' | 'href' | 'className'>> &
  PropsWithChildren<SDK.Components.TextProps>

const Link: FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href = '',
      underline = true,
      type = 'primary',
      strong = false,
      style,
      children,
      icon,
      translate,
      font,
      target = href?.startsWith('http') || href?.startsWith('mailto') ? '_blank' : undefined,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { token } = useColor()
    if (typeof children !== 'string') return children
    return (
      <NavLink
        to={href}
        ref={ref}
        data-cy={'Link'}
        data-title={children}
        rel={target === '_blank' ? 'noreferrer' : undefined}
      >
        <Text
          strong={strong}
          underline={underline}
          style={{
            textDecoration: token.linkDecoration ?? underline ? 'underline' : undefined,
            verticalAlign: 'top',
            color: token.colorLink,
            ...style,
          }}
          color={token.colorLink}
          icon={icon}
          font={font}
          translate={translate}
          {...props}
        >
          {children}
        </Text>
      </NavLink>
    )
  },
)

export { Link }
