
import { BillingAddress } from '../address'
import { ApiEntity } from '../api/entity'

class AccountWallet extends ApiEntity {
  nickname?: string | undefined
  billingUse?: API.WalletBillingUse | undefined
  default?: boolean | undefined
  customerId?: number | undefined
  status: string = 'active'
  lastFour: string = '0000'
  expMonth?: number | undefined
  expYear?: number | undefined
  billingDetails?: BillingAddress | undefined
  paymentType: API.WalletPaymentType = 'creditcard'
  verified?: boolean | undefined
  brand: string = ''
  createdOn: Date = new Date()

  pk() {
    return 'singleton'
  }
}


export { AccountWallet }

