import { type CSSProperties, FC, type PropsWithChildren, useCallback } from 'react'
import { useCss } from 'react-use'

import { ConfigProvider, Flex, Spin, Typography } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import { useI18n } from '../../providers'
import { useColor } from '../app'
import IvyIcon from '../icon'

const TextComponent = Typography.Text

const Text: FC<SDK.Components.TextProps & { children: string }> = ({
  style: initialStyle,
  font = {
    size: undefined,
    weight: 400,
    family: 'primary',
  },
  type,
  size,
  color: defaultColor,
  uppercased = false,
  rows,
  icon,
  iconAlign = 'start',
  strong,
  strike = false,
  children = '',
  loading = false,
  ...props
}) => {
  const { token, textByVariant } = useColor()
  const resolveSize = useCallback(
    (size: SDK.Components.TextProps['size']) => {
      switch (size) {
        case 'small':
          return token.fontSizeSM
        case 'middle':
          return token.fontSize
        case 'large':
          return token.fontSizeLG
        case undefined:
        default:
          return font.size
      }
    },
    [token.fontSize, token.fontSizeLG, token.fontSizeSM, font.size],
  )

  const fontSize = font?.size ?? resolveSize(size)
  const fontWeight = strong ? 600 : font?.weight
  const colorText = defaultColor ? defaultColor : type ? textByVariant(type) : undefined
  const fontFamily = font?.family === 'primary' ? token.fontFamily : token.fontFamilySecondary
  const ellipsisClassName = useCss({
    ['display']: '-webkit-box',
    ['-webkit-line-clamp']: rows,
    ['-webkit-box-orient']: 'vertical',
    overflow: 'hidden',
    ['text-overflow']: 'ellipsis',
  })

  const style: CSSProperties = {
    fontWeight,
    textTransform: uppercased ? 'uppercase' : 'initial',
    textDecoration: strike ? 'line-through' : 'initial',
    flex: 1,
    color: colorText,
    ...initialStyle,
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText,
            fontSize,
            fontFamily,
            lineHeight: 1.5,
            titleMarginBottom: 0,
            titleMarginTop: 0,
            algorithm: true,
          },
        },
      }}
    >
      <Flex align={iconAlign} gap={8} justify={style.textAlign === 'right' ? 'end' : undefined}>
        {!loading && icon && <IvyIcon type={icon} color={type} />}
        <TextComponent className={ellipsisClassName} data-cy={'Text'} style={style} {...props}>
          {children}
        </TextComponent>
        {loading && <Spin indicator={<LoadingOutlined spin />} size='small' />}
      </Flex>
    </ConfigProvider>
  )
}

function withTranslation<P extends SDK.Components.TextProps & PropsWithChildren<{ translate?: 'yes' | 'no' }>>(
  Component: FC<Omit<P, 'translate' | 'context' | 'children'> & { children: string }>,
) {
  return ({ children, translate = 'yes', context, ...props }: P) => {
    const { t } = useI18n()
    // Possibly handle numbers here too
    if (typeof children !== 'string') return children
    if (!children) return null

    if (translate === 'no') return <Component {...props}>{children}</Component>
    return (
      // <ConfigProvider
      //   theme={{
      //     components: {
      //       Tooltip: {
      //         controlHeight: 24,
      //         algorithm: true,
      //       },
      //     },
      //   }}
      // >
      <Component {...props}>{t(children, context)}</Component>
      // </ConfigProvider>
    )
  }
}

export default withTranslation(Text)
export { Text, withTranslation }
